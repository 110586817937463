import HomePage from 'components/HomePage';
import { ISR_REVALIDATE_IN_SECONDS } from 'config/env';
import { wrapper } from 'pages/_app.page';
import { setHomePage } from 'store/homePage/homePageActions';
import { fetchContent } from 'lib/clients/fetchContent';

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  try {
    const homePageContent = await fetchContent({ type: 'homePage' });
    store.dispatch(setHomePage(homePageContent));
  } catch (e) {
    // do nothing - home page content is not yet available on prod
  }
  return {
    props: {},
    revalidate: ISR_REVALIDATE_IN_SECONDS,
  };
});

export default HomePage;
